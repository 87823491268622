import {Component, OnInit} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DataService} from "../data.service";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {RouterLink} from "@angular/router";
import {Order} from "../interfaces";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    NgClass,
    TitleCasePipe,
    FormsModule,
    NgIf,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    RouterLink,
    DatePipe,
  ],
  providers: [DataService],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit{

  constructor(private dataService: DataService, private http: HttpClient) { }

  baseUrl: string = environment.baseUrl;
  isAdmin!: boolean;
  search!: string;
  filter: string = 'all';
  displayedColumns: string[] = ['position', 'title', 'user', 'date', 'status', 'action', 'more'];
  dataSource: any;

  ngOnInit(): void {
    this.dataService.isAdmin$.subscribe(data => {
      this.isAdmin = data;
    });
    if (this.isAdmin) {
      this.http.get<Order[]>(this.baseUrl + `order`).subscribe((data) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
        }
      });
    }
  }

}
