import {Component, ViewChild} from '@angular/core';
import {
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardGroupDirective,
  StripeCardNumberComponent,
  StripeService
} from "ngx-stripe";
import {switchMap} from "rxjs";
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {StripeCardElementOptions, StripeElementsOptions} from "@stripe/stripe-js";
import {environment} from "../../environments/environment";
import {Location, NgIf} from "@angular/common";
import {Order, Service} from "../interfaces";

@Component({
  selector: 'app-checkout',
  imports: [
    ReactiveFormsModule,
    StripeCardExpiryComponent,
    StripeCardCvcComponent,
    StripeCardGroupDirective,
    StripeCardNumberComponent,
    NgIf,
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent {
  @ViewChild(StripeCardNumberComponent) card!: StripeCardNumberComponent;
  userId: any = localStorage.getItem('userId');
  service!: Service;
  stripeTest!: FormGroup;
  baseUrl: string = environment.baseUrl;
  success: boolean = false;
  isLoading: boolean = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#42b546',
        color: '#000000',
        '::placeholder': {
          color: '#5f5f5f',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'es',
  };

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
    });
    if (localStorage.getItem('service')) {
      //@ts-ignore
      this.service = JSON.parse(localStorage.getItem('service'));
    }
  }

  pay(): void {
    this.isLoading = true;
    if (this.stripeTest.valid) {
      this.http.post<any>(this.baseUrl + 'stripe/payment',  {
        service: this.service.name,
      })
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
                payment_method: {
                  card: this.card.element,
                  billing_details: {
                  name: this.stripeTest.getRawValue().name,
                },
              },
            })
          )
        )
        .subscribe((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              this.http.post<Order>(this.baseUrl + 'order',  {
                title: this.service.name, isPackage: false, service: this.service, userId: +this.userId
              }).subscribe((data) => {
                if (data.status === "pending") {
                  this.success = true;
                  this.isLoading = false;
                } else {
                  console.error("Order Failed!!!")
                }
              })
            }
          }
        });
    } else {
      console.error(this.stripeTest);
    }
  }

  back() {
    this.location.back();
  }
}
