<div *ngIf="data.isDocument">
  <h2 mat-dialog-title>{{data.document.user["firstName"] + " " + data.document.user["lastName"] + " | " + data.document.title}}</h2>
  <mat-dialog-content>
    <div class="container">
      <div class="child">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
          <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
        </svg>
        <p>
          {{ data.document.url.split('/')[3] + '/' + data.document.url.split('/')[4] }}
        </p>
      </div>
      <div class="child">
        <p><span>Type</span>: {{ data.document.type }}</p>
        <p><span>Product ID</span>: {{ data.document["productId"] }}</p>
        <p><span>Issue Date</span>: {{ data.document["issueDate"] }}</p>
        <p><span>Expiry Date</span>: {{ data.document["expiryDate"] }}</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="onNoClick()">Done</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Download</button>
  </mat-dialog-actions>
</div>

<div *ngIf="!data.isDocument">
  <h2 mat-dialog-title>{{data.invoice.user["firstName"] + " " + data.invoice.user["lastName"] + " | " + data.invoice.title}}</h2>
  <mat-dialog-content>
    <div class="container">
      <div class="child">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
          <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
        </svg>
        <p>
          {{ data.invoice.url.split('/')[3] + '/' + data.invoice.url.split('/')[4] }}
        </p>
      </div>
      <div class="child">
        <p><span>Date</span>: {{ data.invoice["date"] }}</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="onNoClick()">Done</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Download</button>
  </mat-dialog-actions>

</div>
