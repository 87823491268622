import {Component, inject, OnInit} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Document} from "../interfaces";
import {DataService} from "../data.service";
import {MatDialog} from "@angular/material/dialog";
import {DocumentDialog} from "./document-dialog";

@Component({
  selector: 'app-company-documents',
  standalone: true,
  imports: [
    MatTableModule,
    MatIcon,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    TitleCasePipe,
    DatePipe,
    NgIf
  ],
  templateUrl: './company-documents.component.html',
  styleUrl: './company-documents.component.scss'
})
export class CompanyDocumentsComponent implements OnInit{
  baseUrl: string = environment.baseUrl;
  filter: string = 'all';
  search: any;
  isAdmin!: boolean;

  displayedColumns: string[] = ['id', 'name', 'productId', 'type', 'period', 'action'];
  userDisplayedColumns: string[] = ['id', 'name', 'productId', 'type', 'period', 'status', 'action'];
  dataSource!: MatTableDataSource<Document>;
  filteredDataSource!: MatTableDataSource<Document>;
  dialog = inject(MatDialog);

  constructor(
    private http: HttpClient,
    private dataService: DataService,) { }

  ngOnInit(): void {
    this.dataService.isAdmin$
      .subscribe((data) => {
      this.isAdmin = data;
    });
    if (this.isAdmin) {
      this.http.get<Document[]>(this.baseUrl + 'document')
        .subscribe((data) => {
          if (data) {
            this.dataSource = new MatTableDataSource(data);
            this.filteredDataSource =this.dataSource;
          }
        });
    } else {
      const userId = localStorage.getItem('userId');
      this.http.get<Document[]>(this.baseUrl + `document/user/${userId}`)
        .subscribe((data) => {
          if (data) {
            this.dataSource = new MatTableDataSource(data);
            this.filteredDataSource =this.dataSource;
          }
        });
    }
  }

  openDialog(document: Document) {
    const dialogRef = this.dialog.open(DocumentDialog, {
      data: {
        document: document,
      },
      minWidth: 700
    });

    dialogRef.afterClosed().subscribe((result: Boolean) => {
      if (result) {
        this.getDownloadUrl(document.url);
      }
    });
  }

  getDownloadUrl(fileKey: string) {
    this.http.get<{ downloadUrl: string }>(`${this.baseUrl}document/download?fileKey=${fileKey}`)
      .subscribe( {
        next: ({ downloadUrl }) => {
          console.log(downloadUrl)
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileKey.split('/').pop() || 'downloaded-file';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
          error: (error) => console.error('Download error:', error),
      });
  }

  onInputChange() {
    let temp = this.dataSource.filteredData.filter((data) => {
      return data?.productId.toLowerCase().includes(this.search.toLowerCase());
    })
    this.filteredDataSource = new MatTableDataSource<Document>(temp);
  }

  onFilter() {
    let temp = this.dataSource.filteredData.filter((data) => {
      if(this.filter === 'all') {
        return data;
      } else if (this.filter === 'sent' && this.isAdmin) {
        return data?.isRequested === false;
      } else if (this.filter === 'received' && this.isAdmin){
        return data?.isRequested === true;
      } else if (this.filter === 'sent' && !this.isAdmin) {
        return data?.isRequested === true;
      } else {
        return data?.isRequested === false;
      }
    });
    this.filteredDataSource = new MatTableDataSource<Document>(temp);
  }
}
