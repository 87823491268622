import {Component, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FAQ} from "../interfaces";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-faqs',
    imports: [NgForOf],
    templateUrl: './faqs.component.html',
    styleUrl: './faqs.component.scss',
    animations: [
        trigger('expand', [
            state('hide', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('show', style({ height: '*', visibility: 'visible', })),
            transition('show <=> hide', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('rotate', [
            state('normal', style({})),
            state('rotated', style({ transform: 'rotate(180deg)' })),
            transition('rotated <=> normal', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class FaqsComponent implements OnInit{

  constructor(private http: HttpClient) { }

  baseUrl: string = environment.baseUrl;
  question!: {question: string, answer: string} | null ;
  faqs!: FAQ[];

  ngOnInit(): void {
    this.http.get<FAQ[]>(this.baseUrl + `faq`).subscribe((date) => {
      if (date) {
        this.faqs = date;
      }
    })
  }
}
