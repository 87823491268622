import {Component, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {environment} from "../../environments/environment";
import {Order, Service, User} from "../interfaces";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    NgIf,
    MatTableModule,
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit{
  state: 0 | 1 | 2 = 0;
  baseUrl: string = environment.baseUrl;
  registeredServices: Service[] = [];
  displayedColumns: string[] = ['no', 'name',];
  displayedColumns2: string[] = ['no', 'name', 'action'];
  userId: any = localStorage.getItem('userId');
  services!: MatTableDataSource<Service>;
  isFirst: boolean = true;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.http.get<User>(this.baseUrl + `user/${this.userId}`)
      .subscribe((data) => {
        if (data) {
          this.registeredServices = data.services;
          this.http.get<Service[]>(this.baseUrl + `services`)
            .subscribe((data) => {
              if (data) {
                const temp: Service[] = []
                data.forEach((service) => {
                  const exists = this.registeredServices.some(reg =>
                    reg.id === service.id
                  );
                  if (!exists) {
                    temp.push(service);
                  }
                });
                this.services = new MatTableDataSource<Service>(temp);
              }
            });
        }
      });
    if (!this.isFirst) {
      this.state = 1
    }
  }

  placeOrder(service: Service) {
    this.http.post<Order>(this.baseUrl + 'order',  {
      title: service.name, service, userId: +this.userId
    }).subscribe((data) => {
      if (data) {
        this.isFirst = false;
        this.ngOnInit();
      }
    })
  }

}
