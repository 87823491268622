import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getDownloadUrl(fileKey: string) {
    this.http.get<{ downloadUrl: string }>(`${this.baseUrl}document/download?fileKey=${fileKey}`)
      .subscribe( {
        next: ({ downloadUrl }) => {
          console.log(downloadUrl)
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileKey.split('/').pop() || 'downloaded-file';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        error: (error) => console.error('Download error:', error),
      });
  }
}
