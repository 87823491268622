<div class="documents">
  <div class="flex">
    <div class="icon-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-folder2-open" viewBox="0 0 16 16">
        <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7z"/>
      </svg>
    </div>
    <p class="title">Documents</p>
  </div>
  <div class="filters">
    <select name="filters" id="filter" [(ngModel)]="filter" (ngModelChange)="onFilter()">
      <option value="all">All</option>
      <option value="sent">Sent</option>
      <option value="received">Received</option>
    </select>
    <div class="input">
      <input class="field" [(ngModel)]="search" placeholder="Search" name="search" (ngModelChange)="onInputChange()"/>
      <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </span>
    </div>
  </div>
  <table *ngIf="isAdmin" mat-table [dataSource]="filteredDataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let document">{{document.id}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Company Name </th>
      <td mat-cell *matCellDef="let document"> {{document.title}} </td>
    </ng-container>

    <ng-container matColumnDef="productId">
      <th mat-header-cell *matHeaderCellDef> Product ID </th>
      <td mat-cell *matCellDef="let document"> {{document["productId"]}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let document">{{document.type}}</td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef> Period </th>
      <td mat-cell *matCellDef="let document"> {{document["issueDate"] | date }} - {{document["expiryDate"] | date}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let document">
        <p class="view" (click)="openDialog(document)">
          View
        </p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <table *ngIf="!isAdmin" mat-table [dataSource]="filteredDataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let document">{{document.id}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Company Name </th>
      <td mat-cell *matCellDef="let document"> {{document.title}} </td>
    </ng-container>

    <ng-container matColumnDef="productId">
      <th mat-header-cell *matHeaderCellDef> Product ID </th>
      <td mat-cell *matCellDef="let document"> {{document["productId"]}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let document">{{document.type}}</td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef> Period </th>
      <td mat-cell *matCellDef="let document"> {{document["issueDate"] | date }} - {{document["expiryDate"] | date}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let document">
        <p class="status" [ngClass]="{'read': document.status === 'read', 'unread': document.status === 'unread'}">
          {{document.status | titlecase}}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let document">
        <p class="view" (click)="openDialog(document)">
          View
        </p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="userDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: userDisplayedColumns;"></tr>
  </table>
</div>
