import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DatePipe, NgIf} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Document, Notification} from "../interfaces";
import {environment} from "../../environments/environment";
import {DocumentDialog} from "../company-documents/document-dialog";
import {MatDialog} from "@angular/material/dialog";
import {DataService} from "../data.service";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";

@Component({
    selector: 'app-notifications',
    imports: [MatTableModule, ReactiveFormsModule, FormsModule, NgIf, DatePipe, MatTableResponsiveModule, MatPaginatorModule],
    templateUrl: './notifications.component.html',
    styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit{
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
  ) { }

  baseUrl: string = environment.baseUrl;
  filter: string = 'all';
  dialog = inject(MatDialog);
  document!: Document;
  isExpiring: boolean = false;
  temp: Notification[] = [];
  isAdmin!: boolean;

  displayedColumns: string[] = ['icon', 'title', 'description', 'date', 'action'];
  dataSource!: MatTableDataSource<Notification>;
  filteredDataSource!: MatTableDataSource<Notification>;

  ngOnInit(): void {
    this.dataService.isAdmin$.subscribe(data => {
      this.isAdmin = data;
    });
    const userId = localStorage.getItem("userId");
    this.http.get<Notification[]>(this.baseUrl + `notification/user/${userId}`)
      .subscribe((data) => {
        if (data.length) {
          const param = `${this.route.snapshot.queryParamMap.get('expiring')}`;
          if (param === 'true') {
            this.isExpiring = true;
            data.forEach((notification) => {
              if (notification.title === 'Expiration Notification') {
                this.temp.push(notification);
              }
            })
            if (this.temp.length){
              this.temp.sort((a, b) => {
                if (a.date > b.date) return -1;
                if (a.date < b.date) return 1;
                return 0;
              });
              this.dataSource = new MatTableDataSource<Notification>(this.temp);
            }
          } else {
            data.sort((a, b) => {
              if (a.date > b.date) return -1;
              if (a.date < b.date) return 1;
              return 0;
            });
            this.dataSource = new MatTableDataSource<Notification>(data);
          }
          this.filteredDataSource = this.dataSource;
        }
      })
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  openDialog(documentId: number) {
    if (!this.document) {
      this.http.get<Document>(this.baseUrl + `document/${documentId}`)
        .subscribe((data) => {
          if (data) {
            this.document = data;
            this.dialog.open(DocumentDialog, {
              data: {
                document: this.document,
              },
              minWidth: 700
            });
          }
        });
    } else {
      this.dialog.open(DocumentDialog, {
        data: {
          document: this.document,
        },
        minWidth: 700
      });
    }
  }

  read(notification: Notification) {
    this.http.patch(this.baseUrl + `notification/${notification.id}`, {})
      .subscribe();
    if (notification.title === 'Expiration Notification' || notification.title === 'New Document Received') {
      this.openDialog(notification.item);
    } else if (notification.title === 'New User') {
      this.router.navigate(['/', 'users', 'view', notification.item])
        .then(() => { return; });
    } else if (notification.title === 'New Order Received') {
      this.router.navigate(['/', 'orders', notification.item])
        .then(() => { return; })
    } else if (notification.title === 'Document Request') {
      this.router.navigate(['/', 'documents'])
        .then(() => { return; })
    } else if (notification.title === 'Payment Received') {
    }
  }

  onFilter() {
    let temp = this.dataSource.filteredData.filter((data) => {
      if(this.filter === 'all') {
        return data;
      } else {
        return data?.title.includes(this.filter);
      }
    });
    this.filteredDataSource = new MatTableDataSource<Notification>(temp);
  }

}
