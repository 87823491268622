import {AfterViewInit, Component, inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Document, Invoice, Request} from "../interfaces";
import {DataService} from "../data.service";
import {MatDialog} from "@angular/material/dialog";
import {DocumentDialog} from "./document-dialog";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";

@Component({
    selector: 'app-company-documents',
    imports: [
        MatTableModule,
        ReactiveFormsModule,
        MatTableResponsiveModule,
        FormsModule,
        NgClass,
        TitleCasePipe,
        DatePipe,
        NgIf,
        RouterLink,
        MatPaginatorModule,
    ],
    templateUrl: './company-documents.component.html',
    styleUrl: './company-documents.component.scss'
})
export class CompanyDocumentsComponent implements OnInit, AfterViewInit{
  baseUrl: string = environment.baseUrl;
  filter: string = 'all';
  search: any;
  isAdmin!: boolean;

  displayedColumns: string[] = ['id', 'name', 'productId', 'type', 'period', 'action'];
  userDisplayedColumns: string[] = ['id', 'name', 'productId', 'type', 'period', 'status', 'action'];
  requestColumns: string[] = ['id', 'title', 'subject', 'date', 'status', 'action'];
  invoiceColumns: string[] = ['id', 'title', 'description', 'date', 'action'];
  dataSource!: MatTableDataSource<Document>;
  filteredDataSource!: MatTableDataSource<Document>;
  dialog = inject(MatDialog);
  requests!: MatTableDataSource<Request>;
  invoices!: MatTableDataSource<Invoice>;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.dataService.isAdmin$.subscribe((data) => {
      this.isAdmin = data;
    });
    if (this.isAdmin) {
      const userId = `${this.activatedRoute.snapshot.paramMap.get('userId')}`;
      if (userId === 'null') {
        this.http.get<Document[]>(this.baseUrl + 'document')
          .subscribe((data) => {
            if (data.length) {
              this.dataSource = new MatTableDataSource(data);
              this.filteredDataSource =this.dataSource;
            }
          });
        this.http.get<Invoice[]>(this.baseUrl + `invoice`)
          .subscribe((data) => {
              if (data.length) {
                data.sort((a, b) => {
                  if (a.date > b.date) return -1;
                  if (a.date < b.date) return 1;
                  return 0;
                });
                this.invoices = new MatTableDataSource<Invoice>(data);
              }
          })
      } else {
        this.http.get<Document[]>(this.baseUrl + `document/user/${userId}`)
          .subscribe((data) => {
            if (data.length) {
              this.dataSource = new MatTableDataSource(data);
              this.filteredDataSource =this.dataSource;
            }
          });
        this.http.get<Invoice[]>(this.baseUrl + `invoice/user/${userId}`)
          .subscribe((data) => {
            if (data.length) {
              data.sort((a, b) => {
                if (a.date > b.date) return -1;
                if (a.date < b.date) return 1;
                return 0;
              });
              this.invoices = new MatTableDataSource<Invoice>(data);
            }
          })
      }
    } else {
      const userId = localStorage.getItem('userId');
      this.http.get<Document[]>(this.baseUrl + `document/user/${userId}`)
        .subscribe((data) => {
          if (data.length) {
            this.dataSource = new MatTableDataSource(data);
            this.filteredDataSource = this.dataSource;
          }
        });
      this.http.get<Request[]>(this.baseUrl + `request/user/${userId}`)
        .subscribe((data) => {
          if (data.length) {
            this.requests = new MatTableDataSource<Request>(data);
          }
        })
    }
  }

  @ViewChild('inv') invoicePaginator!: MatPaginator;
  @ViewChild('doc') documentPaginator!: MatPaginator;
  @ViewChild('userInv') userInvoicePaginator!: MatPaginator;
  @ViewChild('userDoc') userDocumentPaginator!: MatPaginator;

  ngAfterViewInit() {
    if (this.isAdmin) {
      this.invoices.paginator = this.invoicePaginator;
      this.filteredDataSource.paginator = this.documentPaginator;
    } else {
      this.invoices.paginator = this.userInvoicePaginator;
      this.filteredDataSource.paginator = this.userDocumentPaginator;
    }
  }

  openInvoiceDialog(invoice: Invoice) {
    const dialogRef = this.dialog.open(DocumentDialog, {
      data: {
        invoice: invoice,
        isDocument: false,
      },
      minWidth: '85%'
    });

    dialogRef.afterClosed().subscribe((result: Boolean) => {
      if (result) {
        this.getDownloadUrl(invoice.url);
      }
    });
  }

  openDialog(document: Document) {
    const dialogRef = this.dialog.open(DocumentDialog, {
      data: {
        document: document,
        isDocument: true,
      },
      minWidth: '85%'
    });

    dialogRef.afterClosed().subscribe((result: Boolean) => {
      if (result) {
        this.getDownloadUrl(document.url);
        this.http.patch<Document>(`${this.baseUrl}document/update/status/${document.id}`,{})
          .subscribe((data) => {
            if (data?.id) {
              this.ngOnInit();
            }
          });
      }
    });
  }

  getDownloadUrl(fileKey: string) {
    this.http.get<{ downloadUrl: string }>(`${this.baseUrl}document/download?fileKey=${fileKey}`)
      .subscribe( {
        next: ({ downloadUrl }) => {
          console.log(downloadUrl)
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileKey.split('/').pop() || 'downloaded-file';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
          error: (error) => console.error('Download error:', error),
      });
  }

  onInputChange() {
    let temp = this.dataSource.filteredData.filter((data) => {
      return data?.user.username.toLowerCase().includes(this.search.toLowerCase());
    })
    this.filteredDataSource = new MatTableDataSource<Document>(temp);
  }

  onFilter() {
    let temp = this.dataSource.filteredData.filter((data) => {
      if(this.filter === 'all') {
        return data;
      } else if (this.filter === 'sent' && this.isAdmin) {
        return data?.isRequested === false;
      } else if (this.filter === 'received' && this.isAdmin){
        return data?.isRequested === true;
      } else if (this.filter === 'sent' && !this.isAdmin) {
        return data?.isRequested === true;
      } else {
        return data?.isRequested === false;
      }
    });
    this.filteredDataSource = new MatTableDataSource<Document>(temp);
  }
}
