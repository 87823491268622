<div class="orders">
  <div class="flex">
    <div class="icon-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
      </svg>
    </div>
    <p class="title">Orders</p>
  </div>
  <div class="filters">
    <select name="filters" id="filter" [(ngModel)]="filter" (ngModelChange)="onFilter()">
      <option value="all">All</option>
      <option value="pending">Pending</option>
      <option value="completed">Completed</option>
      <option value="cancelled">Cancelled</option>
    </select>
    <div class="input">
      <input class="field" [(ngModel)]="search" placeholder="Search By User" name="search" (ngModelChange)="onInputChange()"/>
      <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </span>
    </div>
  </div>
  <table mat-table matTableResponsive [dataSource]="filteredDataSource" class="mat-elevation-z8">
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>
        No.
      </th>
      <td mat-cell *matCellDef="let i = index"> {{ i + 1 }} </td>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Title </th>
      <td mat-cell *matCellDef="let order"> {{order.title}} </td>
    </ng-container>

    <!-- Weight User -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> User </th>
      <td mat-cell *matCellDef="let order">
        {{order.user["firstName"] + " " + order.user["lastName"]}}
      </td>
    </ng-container>

    <ng-container matColumnDef="package">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let order">
        {{order["isPackage"] ? 'Package' : 'Service'}}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let order"> {{order.date | date}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let order">
        <p class="status" [ngClass]="{
            'pending': order.status === 'pending',
            'completed': order.status === 'completed',
            'cancelled': order.status === 'cancelled'}"
        >
          {{order.status | titlecase}}
        </p>
      </td>
    </ng-container>

    <!-- More Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let order">
        <button mat-icon-button aria-label="more" [matMenuTriggerFor]="more" [matMenuTriggerData]="{order: order}">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
      <mat-menu #more="matMenu">
        <ng-template matMenuContent let-order="order">
          <span mat-menu-item (click)="selectOrder(order)">View Order</span>
          <span *ngIf="order.status !== 'completed'" mat-menu-item (click)="deleteOrder(order.id)">Delete Order</span>
        </ng-template>
      </mat-menu>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20]"
                 #paginator
                 showFirstLastButtons="true">
  </mat-paginator>
</div>
