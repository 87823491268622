<div class="navigation">
  <div class="logo-section">
    <img class="logo" src="/assets/logo1.png" alt="profile picture">
    <div class="user-text">
      <p class="company">TRI-BROTHERS</p>
      <p class="detail">Dashboard</p>
    </div>
  </div>
  <div class="flex-container">
    <p class="title" *ngIf="page!== 'FAQ'">{{ page | titlecase }}</p>
    <p class="title" *ngIf="page=== 'FAQ'">{{ page }}</p>
    <div class="child">
      <button class="notification" [matMenuTriggerFor]="notification" aria-label="notification">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
        </svg>
      </button>
      <mat-menu #notification="matMenu">
<!--        <ng-container *ngFor=""></ng-container>-->
        <button mat-menu-item>
          <span>Redial</span>
        </button>
        <button mat-menu-item>
          <span>Disable alerts</span>
        </button>
      </mat-menu>
      <button class="menu" [matMenuTriggerFor]="menu">
        <img class="pfp" src="/assets/logo1.png" alt="profile picture">
        <div class="user-text">
          <p class="username">{{username}}</p>
          <p class="detail">{{ isAdmin ? "Administrator" : "User"}}</p>
        </div>
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
          </svg>
        </span>
      </button>
      <mat-menu #menu="matMenu">
<!--        <button mat-menu-item>Settings</button>-->
<!--        <button mat-menu-item>Help</button>-->
        <button mat-menu-item [routerLink]="['/', 'users', 'create']">Create Account</button>
        <button mat-menu-item (click)="logOut()">Sign Out</button>
      </mat-menu>
    </div>
  </div>
</div>
