import {Component, inject, OnInit} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Document, Notification} from "../interfaces";
import {environment} from "../../environments/environment";
import {DocumentDialog} from "../company-documents/document-dialog";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [MatTableModule, ReactiveFormsModule, TitleCasePipe, NgClass, FormsModule, NgIf, RouterLink, DatePipe],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit{
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  baseUrl: string = environment.baseUrl;
  filter: string = 'all';
  dialog = inject(MatDialog);
  document!: Document;
  isExpiring: boolean = false;
  temp: Notification[] = [];

  displayedColumns: string[] = ['icon', /*'id',*/ 'title', 'description', 'date', 'action'];
  dataSource!: MatTableDataSource<Notification>;

  ngOnInit(): void {
    const userId = localStorage.getItem("userId");
    this.http.get<Notification[]>(this.baseUrl + `notification/user/${userId}`)
      .subscribe((data) => {
        if (data.length) {
          const param = `${this.route.snapshot.queryParamMap.get('expiring')}`;
          if (param === 'true') {
            this.isExpiring = true;
            data.forEach((notification) => {
              if (notification.title === 'Expiration Notification') {
                this.temp.push(notification);
              }
            })
            if (this.temp.length){
              this.temp.sort((a, b) => {
                if (a.date > b.date) return -1;
                if (a.date < b.date) return 1;
                return 0;
              });
              this.dataSource = new MatTableDataSource<Notification>(this.temp);
            }
          } else {
            data.sort((a, b) => {
              if (a.date > b.date) return -1;
              if (a.date < b.date) return 1;
              return 0;
            });
            this.dataSource = new MatTableDataSource<Notification>(data);
          }
        }
      })
  }

  openDialog(documentId: number) {
    if (!this.document) {
      this.http.get<Document>(this.baseUrl + `document/${documentId}`)
        .subscribe((data) => {
          if (data) {
            this.document = data;
            this.dialog.open(DocumentDialog, {
              data: {
                document: this.document,
              },
              minWidth: 700
            });
          }
        });
    } else {
      this.dialog.open(DocumentDialog, {
        data: {
          document: this.document,
        },
        minWidth: 700
      });
    }
  }

}
