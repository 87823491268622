<div class="services">
  <ng-container *ngIf="state === 0">
    <p class="title">Select Option:</p>
    <div class="container">
      <div class="card" (click)="state = 1">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
          <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
        </svg>
      </span>
        <p class="name">My Services</p>
      </div>
      <div class="card" (click)="state = 2">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
        </svg>
      </span>
        <p class="name">Purchase New Service</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="state === 1">
    <p class="title">My Services</p>
    <table mat-table [dataSource]="registeredServices" class="mat-elevation-z8">
      <ng-container matColumnDef="no">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let service; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Service </th>
        <td mat-cell *matCellDef="let service">
          {{service.name}}
        </td>
      </ng-container>

<!--      <ng-container matColumnDef="phone">-->
<!--        <th mat-header-cell *matHeaderCellDef> Phone No. </th>-->
<!--        <td mat-cell *matCellDef="let user"> {{user["phone"]}} </td>-->
<!--      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="button-container">
      <button class="back-button" (click)="state = 0">
        Back
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="state === 2">
    <p class="title">Purchase Services</p>
    <table mat-table [dataSource]="services" class="mat-elevation-z8">
      <ng-container matColumnDef="no">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let service; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Service </th>
        <td mat-cell *matCellDef="let service">
          {{service.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let service">
          <a class="link" (click)="placeOrder(service)">Order Service</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>

    <div class="button-container">
      <button class="back-button" (click)="state = 0">
        Back
      </button>
    </div>
  </ng-container>
</div>
