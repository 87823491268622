import {Component} from '@angular/core';
import {User} from "../interfaces";
import {environment} from "../../environments/environment";
import {DatePipe, Location, TitleCasePipe} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Clipboard} from "@angular/cdk/clipboard";
import {MatIconButton} from "@angular/material/button";

@Component({
  selector: 'app-view-user',
  standalone: true,
  imports: [
    MatIconButton,
    TitleCasePipe,
    DatePipe,
    RouterLink
  ],
  templateUrl: './view-user.component.html',
  styleUrl: '../view-order/view-order.component.scss'
})
export class ViewUserComponent {

  userId: any;
  user!: User;
  baseUrl: string = environment.baseUrl;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private clipboard: Clipboard
  ) {  }

  clickToCopy(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
    this.http.get<User>(this.baseUrl + `user/${this.userId}`)
      .subscribe((data) => {
        if (data) {
          this.user = data;
        }
      });
  }


  navigateBack() {
    this.location.back();
  }

  changeActivationStatus() {
    this.http.patch<User>(this.baseUrl + `user/active/${this.userId}`, {})
      .subscribe((data) => {
        if (data) {
          this.user = data;
        }
      });
  }
}
