import {Component} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Location, NgIf} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError, filter, of, tap} from "rxjs";
// import * as jose from "jose";

@Component({
  selector: 'app-create-account',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
  ],
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.scss'
})
export class CreateAccountComponent {
  baseUrl: string = environment.baseUrl;
  firstName!: string;
  lastName!: string;
  username!: string;
  phone!: string;
  email!: string;
  address1!: string;
  address2!: string;
  city!: string;
  zipCode!: string;
  password!: string;
  confirmPassword!: string;

  success: boolean = false;

  constructor(private location: Location,
              private http: HttpClient) {
  }

  done() {
    this.firstName = '';
    this.lastName = '';
    this.username = '';
    this.phone = '';
    this.email = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.zipCode = '';
    this.password = '';
    this.confirmPassword = '';
    this.success = false;
  }

  navigateBack() {
    this.location.back();
  }

  createUser() {
    this.http.post(this.baseUrl + 'user', {
      username: this.username, password: this.password, lastName: this.lastName,
      firstName: this.firstName, email: this.email, phone: this.phone, address1: this.address1,
      address2: this.address2, city: this.city, zipcode: this.zipCode
    }).pipe(
      catchError((err) => {
        console.error(err);
        return of(false);
      }),
      filter((response: any) => !!response),
      tap((response: any): void => {
        console.log(response);
      })
    ).subscribe((data) => {
      if (data) {
        this.success = true;
      }
    });
  }
}
