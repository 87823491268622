<div class="attach" *ngIf="!success">
  <div class="header">
    <div class="flex">
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
          <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
        </svg>
      </span>
      <p *ngIf="isOrder" class="title">{{ 'order.title | order.user'}}</p>
      <p *ngIf="!isOrder" class="title">Send Document</p>
    </div>
    <p *ngIf="isOrder" class="title">Order Details</p>
  </div>
  <p class="title page-title">Attach Document</p>
  <div class="section">
    <div class="grid">
      <div class="child">
        <p>Document Title</p>
        <input type="text" [(ngModel)]="title" name="title" class="title-input" placeholder="Enter Document Title" required>
      </div>
      <div class="child" *ngIf="!isRequest">
        <p>Product ID</p>
        <input type="text" [(ngModel)]="productId" name="title" class="title-input" placeholder="Enter Product ID">
      </div>
      <div class="child" *ngIf="!isRequest">
        <p>Document Type</p>
        <select name="type" class="type" [(ngModel)]="type">
          <ng-container *ngFor="let service of services">
            <option [value]=service.name>{{service.name}}</option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="dates">
      <div>
<!--        <p>Issue Date</p>-->
        <mat-form-field appearance="outline">
          <mat-label>Issue Date</mat-label>
          <input matInput [matDatepicker]="issue" [formControl]="issueDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="issue"></mat-datepicker-toggle>
          <mat-datepicker #issue></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
<!--        <p>Expiry Date</p>-->
        <mat-form-field appearance="outline">
          <mat-label>Expiry Date</mat-label>
          <input matInput [matDatepicker]="expiry" [formControl]="expiryDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="expiry"></mat-datepicker-toggle>
          <mat-datepicker #expiry></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="upload">
      <p>Upload File</p>
      <app-drag-and-drop-upload
        (fileUploaded)="onFileUploaded($event)"
      ></app-drag-and-drop-upload>
    </div>
  </div>
  <div class="buttons">
    <button class="back-button" (click)="navigateBack()">Back</button>
    <button class="send-button" (click)="sendDocument()">
      <span>Finish</span>
      <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
          </svg>
        </span>
    </button>
  </div>
</div>

<div *ngIf="success" class="success">
  <div class="container">
    <span class="icon-container">
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
        </svg>
      </span>
    </span>
    <p class="heading">Document Sent Successfully</p>
    <p class="extra-text">Document has been sent<br/> successfully</p>
    <button class="finish" (click)="done()">Done</button>
  </div>
</div>
