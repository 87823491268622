import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {Location, TitleCasePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Order, User} from "../interfaces";
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-view-order',
    imports: [
        RouterLink,
        TitleCasePipe,
    ],
    templateUrl: './view-order.component.html',
    styleUrl: './view-order.component.scss'
})
export class ViewOrderComponent implements OnInit{
  orderId: any;
  order!: Order;
  user!: User;
  baseUrl: string = environment.baseUrl;
  private _snackBar = inject(MatSnackBar);

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private clipboard: Clipboard,
    private router: Router
  ) {  }

  clickToCopy(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    this.openSnackBar()
  }

  ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    const temp = JSON.parse(`${localStorage.getItem('order')}`);
    if (temp.id == this.orderId) {
      this.order = temp;
      this.user = this.order.user;
    } else {
      this.http.get<Order>(this.baseUrl + `order/${this.orderId}`)
        .subscribe((data) => {
          if (data) {
            this.order = data;
            this.user = this.order.user;
          }
        });
    }
  }

  openSnackBar() {
    this._snackBar.open("Copied", 'Ok', {
      duration: 2000,
    });
  }

  navigateBack() {
    this.location.back();
  }

  send(order: Order) {
    localStorage.setItem('order', JSON.stringify(order));
    this.router.navigate(['/', 'orders', order.id,'attach']).then(()=> {return;});
  }
}
