import {Component, OnInit} from '@angular/core';
import {DragAndDropUploadComponent} from "../drag-and-drop-upload/drag-and-drop-upload.component";
import {FormsModule} from "@angular/forms";
import {Location, NgIf} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-request-document',
  standalone: true,
  imports: [
    DragAndDropUploadComponent,
    FormsModule,
    NgIf
  ],
  templateUrl: './request-document.component.html',
  styleUrl: './../attach-files/attach-files.component.scss'
})
export class RequestDocumentComponent implements OnInit{
  title!: string;
  subject!: string;
  userId: any;
  baseUrl: string = environment.baseUrl;

  constructor(
    private location:Location,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
  }

  navigateBack() {
    this.location.back();
  }

  sendRequest() {
    this.http.post(this.baseUrl + `request`, {
      title: this.title, subject: this.subject, userId: this.userId
    }).subscribe((data) => {
      if (data) {
        this.title = '';
        this.subject = '';
      }
    })
  }

}
