import {Component, OnInit} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {User} from "../interfaces";
import {environment} from "../../environments/environment";
import {state} from "@angular/animations";

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    MatIconButton,
    RouterLink
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {

  baseUrl: string = environment.baseUrl;
  search!: string;
  dataSource!: MatTableDataSource<User>;
  filteredDataSource!: MatTableDataSource<User>;
  displayedColumns: string[] = ['id', 'name', 'phone', 'bought', 'regDate', 'action', /*'more'*/];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<User[]>(this.baseUrl + `user`)
      .subscribe((data) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
          this.filteredDataSource = this.dataSource;
        }
      });
  }

  onInputChange() {
    let temp = this.dataSource.filteredData.filter((data) => {
      return data?.firstName.toLowerCase().includes(this.search.toLowerCase());
    })
    this.filteredDataSource = new MatTableDataSource<User>(temp);
  }

  protected readonly state = state;
}
