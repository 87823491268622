import {Component, Input, OnInit} from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import {MatDivider} from "@angular/material/divider";
import {NgForOf, NgIf, NgOptimizedImage, TitleCasePipe} from "@angular/common";
import {MatBadge} from "@angular/material/badge";
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  imports: [MatButtonModule, MatMenuModule, MatDivider, NgForOf, MatBadge, NgOptimizedImage, TitleCasePipe, NgIf, RouterLink],
  standalone: true
})
export class NavComponent implements OnInit{

  username: string = `${localStorage.getItem('username')}`;
  constructor(public router: Router, private authService: AuthService) {
  }

  @Input() isAdmin!: boolean;
  page: string = 'User';

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
         switch (this.router.url.split('?')[0].substring(1).split('/')[0]) {
           case 'dashboard' || 'adminDashboard':
             this.page = "Dashboard";
             break;
           case 'faq' || 'adminFAQ':
             this.page = "FAQ";
             break;
           case 'notifications' || 'adminNotification':
             this.page = "Notification";
             break
           case 'send-invoices' || 'send-documents' || 'company-documents':
             this.page = this.router.url.split('?')[0].substring(1).split('/')[0].replace('-', ' ');
             break;
           default:
             this.page = this.router.url.split('?')[0].substring(1).split('/')[0];
             break;
         }
      }
    });
  }

  logOut() {
    localStorage.removeItem('access_token');
    this.authService.logOut();
    this.router.navigate(['login']).then(() => {return;})
  }

  protected readonly localStorage = localStorage;
}
