import {Component, OnInit } from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {RouterLink} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Order} from "../interfaces";

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    MatTableModule,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    MatIconButton,
    MatMenuModule,
    TitleCasePipe,
    NgClass,
    RouterLink,
    DatePipe
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent implements OnInit {

  baseUrl: string = environment.baseUrl;
  search!: string;
  filter: string = 'all';
  displayedColumns: string[] = ['position', 'title', 'user', 'date', 'package', 'status', 'more'];
  dataSource!: MatTableDataSource<Order>;
  filteredDataSource!: MatTableDataSource<Order>;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get<Order[]>(this.baseUrl + `order`).subscribe((date) => {
      if (date) {
        this.dataSource = new MatTableDataSource(date);
        this.filteredDataSource = this.dataSource;
      }
    })
  }

  onInputChange() {
    let temp = this.dataSource.filteredData.filter((data) => {
      return data?.user.firstName.toLowerCase().includes(this.search.toLowerCase());
    })
    this.filteredDataSource = new MatTableDataSource<Order>(temp);
  }

  onFilter() {
    let temp = this.dataSource.filteredData.filter((data) => {
      if(this.filter === 'all') {
        return data;
      } else {
        return data?.status.toLowerCase().includes(this.filter);
      }
    });
    this.filteredDataSource = new MatTableDataSource<Order>(temp);
  }

}
