import {Component, inject, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {Invoice} from "../interfaces";
import {environment} from "../../environments/environment";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {DocumentDialog} from "../company-documents/document-dialog";
import {MatDialog} from "@angular/material/dialog";
import {SharedService} from "../shared.service";

@Component({
    selector: 'app-invoices',
    imports: [
        FormsModule,
        MatTableModule,
        MatTableResponsiveModule
    ],
    templateUrl: './invoices.component.html',
    styleUrl: './invoices.component.scss'
})
export class InvoicesComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private sharedService: SharedService) { }

  baseUrl: string = environment.baseUrl;
  filter: string = 'all';
  search: any;
  userId!: any;

  displayedColumns: string[] = ['id', 'title', 'date', 'actions'];
  dataSource: MatTableDataSource<Invoice> = new MatTableDataSource();
  dialog = inject(MatDialog);


  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    this.http.get<Invoice[]>(this.baseUrl + `invoice/user/${this.userId}`)
      .subscribe((data) => {
        if (data) {
          this.dataSource.data = data;
        }
    });
  }

  openDialog(invoice: Invoice) {
    const dialogRef = this.dialog.open(DocumentDialog, {
      data: {
        invoice: invoice,
        isDocument: false,
      },
      minWidth: '85%'
    });

    dialogRef.afterClosed().subscribe((result: Boolean) => {
      if (result) {
        this.sharedService.getDownloadUrl(invoice.url);
      }
    });
  }

}
