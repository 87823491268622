import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {DatePipe, Location, TitleCasePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Order} from "../interfaces";
import { Clipboard } from '@angular/cdk/clipboard';
import {MatIconButton} from "@angular/material/button";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-view-order',
  standalone: true,
  imports: [
    RouterLink,
    TitleCasePipe,
    MatIconButton,
    DatePipe
  ],
  templateUrl: './view-order.component.html',
  styleUrl: './view-order.component.scss'
})
export class ViewOrderComponent implements OnInit{
  orderId: any;
  order!: Order;
  baseUrl: string = environment.baseUrl;
  private _snackBar = inject(MatSnackBar);


  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private clipboard: Clipboard
  ) {  }

  clickToCopy(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    this.openSnackBar()
  }

  ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    this.http.get<Order>(this.baseUrl + `order/${this.orderId}`)
      .subscribe((data) => {
        if (data) {
          this.order = data;
        }
      });
  }

  openSnackBar() {
    this._snackBar.open("Copied", 'Ok', {
      duration: 3000,
    });
  }

  navigateBack() {
    this.location.back();
  }
}
