import {Component, OnInit} from '@angular/core';
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {User} from "../interfaces";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-select-user',
  standalone: true,
  imports: [
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    RouterLink
  ],
  templateUrl: './select-user.component.html',
  styleUrl: '../users/users.component.scss'
})
export class SelectUserComponent implements OnInit{

  selectFor!: string;
  baseUrl: string = environment.baseUrl;
  search!: string;

  displayedColumns: string[] = ['id', 'name', 'phone', 'bought', 'regDate', 'action'];
  dataSource!: MatTableDataSource<User>;
  filteredDataSource!: MatTableDataSource<User>;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient) { }

  ngOnInit(): void {
    switch (this.route.snapshot.url[0].path) {
      case 'document':
        this.selectFor = 'document';
        break;
      case 'invoice':
        this.selectFor = 'invoice';
        break;
      default:
        this.selectFor = 'request';
        break;
    }
    this.http.get<User[]>(this.baseUrl + `user`)
      .subscribe((data) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
          this.filteredDataSource = this.dataSource;
        }
      });
  }

  onInputChange() {
    let temp = this.dataSource.filteredData.filter((data) => {
      return data?.firstName.toLowerCase().includes(this.search.toLowerCase());
    })
    this.filteredDataSource = new MatTableDataSource<User>(temp);
  }
}
