<div class="create-user" *ngIf="!success">
  <div class="header">
    <div class="flex">
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
          <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
        </svg>
      </span>
      <p class="title">Create New Account</p>
    </div>
  </div>
  <p class="title page-title">Enter Information</p>
  <div class="section">
    <form (ngSubmit)="createUser()">
      <div class="grid">
        <div class="child">
          <p>First Name</p>
          <input type="text" [(ngModel)]="firstName" name="first-name" class="input" placeholder="Enter First Name" required>
        </div>
        <div class="child">
          <p>Last Name</p>
          <input type="text" [(ngModel)]="lastName" name="last-name" class="input" placeholder="Enter Last Name" required>
        </div>
        <div class="child">
          <p>Username</p>
          <input type="text" [(ngModel)]="username" name="username" class="input" placeholder="Enter Username" required>
        </div>
        <div class="child">
          <p>Phone Number</p>
          <input type="text" [(ngModel)]="phone" name="phone" class="input" placeholder="Enter Phone Number" required>
        </div>
        <div class="child">
          <p>Email</p>
          <input type="text" [(ngModel)]="email" name="email" class="input" placeholder="Enter Email" required>
        </div>
        <div class="child">
          <p>Address Line 1</p>
          <input type="text" [(ngModel)]="address1" name="address1" class="input" placeholder="Enter Primary Address" required>
        </div>
        <div class="child">
          <p>Address Line 2</p>
          <input type="text" [(ngModel)]="address2" name="address2" class="input" placeholder="Enter Secondary Address" required>
        </div>
        <div class="child">
          <p>City</p>
          <input type="text" [(ngModel)]="city" name="city" class="input" placeholder="Enter City" required>
        </div>
        <div class="child">
          <p>ZIP Code</p>
          <input type="text" [(ngModel)]="zipCode" name="ZIP Code" class="input" placeholder="Enter ZIP Code" required>
        </div>
      </div>
      <div class="flex">
        <div class="child">
          <p>Password</p>
          <input type="password" [(ngModel)]="password" name="password" class="input" placeholder="Enter Password" required>
        </div>
        <div class="child">
          <p>Confirm Password</p>
          <input type="password" [(ngModel)]="confirmPassword" name="confirm-password" class="input" placeholder="Confirm Password" required>
        </div>
      </div>
      <div class="buttons">
        <button class="back-button" (click)="navigateBack()">Back</button>
        <button class="send-button" type="submit">
          <span>Finish</span>
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
            </svg>
          </span>
        </button>
      </div>
    </form>
  </div>
</div>

<div *ngIf="success" class="success">
  <div class="container">
    <span class="icon-container">
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
        </svg>
      </span>
    </span>
    <p class="heading">User Created Successfully</p>
    <p class="extra-text">User has been created<br/> successfully</p>
    <button class="finish" (click)="done()">Done</button>
  </div>
</div>
