import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Location} from "@angular/common";
import {DragAndDropUploadComponent} from "../drag-and-drop-upload/drag-and-drop-upload.component";
import { HttpClient } from "@angular/common/http";
import {Invoice} from "../interfaces";
import {environment} from "../../environments/environment";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-generate-invoice',
    imports: [
        FormsModule,
        DragAndDropUploadComponent
    ],
    templateUrl: './generate-invoice.component.html',
    styleUrl: './generate-invoice.component.scss'
})
export class GenerateInvoiceComponent implements OnInit{

  baseUrl: string = environment.baseUrl;
  userId: any;
  title: any;
  url: any;

  constructor(
    private location: Location,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
  }

  navigateBack() {
    this.location.back();
  }

  onFileUploaded(fileUploadEvent: { url: string; index: number }): void {
    this.url = fileUploadEvent.url;
    console.log(this.url);
  }

  sendInvoice() {
    if (this.userId && this.title && this.url) {
      console.log('sending');
      this.http.post<Invoice>(this.baseUrl + 'invoice', {
        title: this.title, url: this.url, userId: this.userId
      }).subscribe((data) => {
        if (data) {
          this.location.back();
        }
      });
    }
  }

}
