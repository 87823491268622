import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Order} from "../interfaces";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";

@Component({
    selector: 'app-orders',
  imports: [
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    MatIconButton,
    MatMenuModule,
    TitleCasePipe,
    NgClass,
    DatePipe,
    MatTableResponsiveModule,
    MatPaginatorModule,
    NgIf,
  ],
    templateUrl: './orders.component.html',
    styleUrl: './orders.component.scss'
})
export class OrdersComponent implements OnInit, AfterViewInit {

  baseUrl: string = environment.baseUrl;
  search!: string;
  filter: string = 'all';
  orders: Order[] = [];
  displayedColumns: string[] = ['position', 'title', 'user', 'date', 'package', 'status', 'actions'];
  dataSource: MatTableDataSource<Order> = new MatTableDataSource();
  filteredDataSource: MatTableDataSource<Order> = new MatTableDataSource();

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    const userId = `${this.activatedRoute.snapshot.paramMap.get('userId')}`;
    if (userId === 'null') {
      this.http.get<Order[]>(this.baseUrl + `order`)
        .subscribe((data) => {
          this.handleIncomingData(data);
        })
    } else {
      this.http.get<Order[]>(this.baseUrl + `order/user/${userId}`)
        .subscribe((data) => {
          this.handleIncomingData(data);
      })
    }
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  ngAfterViewInit() {
    this.filteredDataSource.paginator = this.paginator;
  }

  handleIncomingData (data: Order []) {
    if (data.length) {
      data.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });
      this.orders = data;
      this.dataSource.data = data;
      this.filteredDataSource.data = data;
    }
  }

  onInputChange() {
    this.filteredDataSource.data = this.dataSource.filteredData.filter((data) => {
      return data?.user.firstName.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  onFilter() {
    this.filteredDataSource.data = this.dataSource.filteredData.filter((data) => {
      if (this.filter === 'all') {
        return data;
      } else {
        return data?.status.toLowerCase().includes(this.filter);
      }
    });
  }

  selectOrder(order: Order) {
    localStorage.setItem('order', JSON.stringify(order));
    this.router.navigate(['/', 'orders', order.id]).then(()=> {return;});
  }

  deleteOrder(orderId: number) {
    this.http.delete<any>(this.baseUrl + `order/${orderId}`)
      .subscribe((data) => {
        if (data["affected"] === 1) {
          this.orders.forEach((order, index) => {
            if (order.id === orderId) {
              this.orders.splice(index, 1);
              this.dataSource.data = this.orders;
              this.filteredDataSource.data = this.orders;
            }
          })
        }
      })
  }

}
