import {Component, OnInit} from '@angular/core';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {FormsModule} from "@angular/forms";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {environment} from "../../environments/environment";
import {Order} from "../interfaces";
import {HttpClient} from "@angular/common/http";
import {DatePipe, DecimalPipe, NgIf} from "@angular/common";

@Component({
    selector: 'app-financials',
  imports: [MatButtonToggleModule, FormsModule, MatIconModule, MatTableModule, MatTableResponsiveModule, NgIf, DatePipe, DecimalPipe],
    templateUrl: './financials.component.html',
    styleUrl: './financials.component.scss'
})
export class FinancialsComponent implements OnInit {
  revenueFilter: string = 'yearly';
  itemsFilter: string = 'yearly';
  baseUrl: string = environment.baseUrl;
  filteredDataSource!: MatTableDataSource<Order>;
  filter: string = 'all';
  search!: string;
  sold!: Order[];
  displayedColumns: string[] = ['icon', 'title', 'user', 'phone', 'bought', 'amount', 'action'];
  dataSource!: MatTableDataSource<Order>;
  today: Date = new Date();
  lastYear: Date = new Date();
  lastMonth: Date = new Date();

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.lastYear.setFullYear(this.today.getFullYear() - 1);
    this.lastMonth.setMonth(this.today.getMonth() - 1);
    this.http.get<Order[]>(this.baseUrl + `order`)
      .subscribe((data) => {
        if (data.length) {
          this.sold = data.filter((order) => {
            return order.status === "completed";
          })
          this.sold.sort((a, b) => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            return 0;
          })
          this.dataSource = new MatTableDataSource(this.sold);
          this.filteredDataSource = this.dataSource;
        }
      })
  }

  calculateRevenue( ): number {
    if (this.revenueFilter === 'yearly') {
      let yearlyOrder = this.sold.filter((order) => {
        return order.date <= this.today && order.date >= this.lastYear;
      })
      return yearlyOrder.reduce((acc) => acc + 50, 0);
    } else if (this.revenueFilter === 'monthly') {
      let monthlyOrder = this.sold.filter((order) => {
        return order.date <= this.today && order.date >= this.lastMonth;
      })
      return monthlyOrder.reduce((acc) => acc + 50, 0);
    } else {
      let dailyOrder = this.sold.filter((order) => {
        return order.date === this.today;
      })
      return dailyOrder.reduce((acc) => acc + 50, 0);
    }
  }

  countItems( ): number {
    if (this.itemsFilter === 'yearly') {
      let yearlyOrder = this.sold.filter((order) => {
        return order.date <= this.today && order.date >= this.lastYear;
      })
      return yearlyOrder.length;
    } else if (this.itemsFilter === 'monthly') {
      let monthlyOrder = this.sold.filter((order) => {
        return order.date <= this.today && order.date >= this.lastMonth;
      })
      return monthlyOrder.length;
    } else {
      let dailyOrder = this.sold.filter((order) => {
        return order.date === this.today;
      })
      return dailyOrder.length;
    }
  }


}
