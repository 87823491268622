import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterOutlet} from '@angular/router';
import {NgClass, NgIf} from "@angular/common";
import {NavComponent} from "./nav/nav.component";
import {MatDivider} from "@angular/material/divider";
import {DataService} from "./data.service";
import {Subscription} from "rxjs";
import {AuthService} from "./auth/auth.service";

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, NgIf, NavComponent, MatDivider, NgClass, RouterLink],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'tri-brothers';
  url!: string;
  subscription!: Subscription;
  adminSubscription!: Subscription;
  isAdmin!: boolean;
  isLoggedIn!: boolean;

  constructor(public router: Router,
              private dataService: DataService,
              public authService: AuthService,) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = this.router.url.split('?')[0].split('/')[1];
      }
    });
    this.subscription = this.authService.isLoggedIn$.subscribe((data) => {
      this.isLoggedIn = data;
    });
    this.adminSubscription = this.dataService.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.adminSubscription.unsubscribe();
  }

}
