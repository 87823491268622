import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {FormsModule} from "@angular/forms";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {environment} from "../../environments/environment";
import {Order} from "../interfaces";
import {HttpClient} from "@angular/common/http";
import {DatePipe, DecimalPipe, NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-financials',
  imports: [MatButtonToggleModule, FormsModule, MatIconModule, MatTableModule, MatTableResponsiveModule, NgIf, DatePipe, DecimalPipe, MatPaginator],
    templateUrl: './financials.component.html',
    styleUrl: './financials.component.scss'
})
export class FinancialsComponent implements OnInit, AfterViewInit {
  revenueFilter: string = 'yearly';
  itemsFilter: string = 'yearly';
  serviceSold: number = 0;
  revenueMade: number = 0;
  baseUrl: string = environment.baseUrl;
  filteredDataSource: MatTableDataSource<Order> = new MatTableDataSource();
  filter: string = 'all';
  search!: string;
  sold: Order[] = [];
  displayedColumns: string[] = ['icon', 'user', 'service', 'amount', 'actions'];
  dataSource: MatTableDataSource<Order> = new MatTableDataSource();
  today: Date = new Date();
  lastYear: Date = new Date();
  lastMonth: Date = new Date();

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.lastYear.setFullYear(this.today.getFullYear() - 1);
    this.lastMonth.setMonth(this.today.getMonth() - 1);
    this.lastMonth.setHours(0, 0, 0, 0);
    this.today.setHours(0, 0, 0, 0);
    this.lastYear.setHours(0, 0, 0, 0);
    this.http.get<Order[]>(this.baseUrl + `order`)
      .subscribe((data) => {
        if (data.length) {
          this.sold = data.filter((order) => {
            return order.status === "completed";
          })
          this.sold.sort((a, b) => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            return 0;
          })
          this.dataSource.data = this.sold;
          this.filteredDataSource.data = this.sold;
        }
        this.calculateRevenue();
        this.countItems();
      });
  }
  //todo: fix for package orders

  @ViewChild('paginator') paginator!: MatPaginator;

  ngAfterViewInit() {
    this.filteredDataSource.paginator = this.paginator;
  }

  calculateRevenue( ) {
    if (this.revenueFilter === 'yearly') {
      let yearlyOrder = this.sold.filter((order) => {
        let orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate <= this.today && orderDate >= this.lastYear;
      })
       this.revenueMade = yearlyOrder.length * 50;
    } else if (this.revenueFilter === 'monthly') {
      let monthlyOrder = this.sold.filter((order) => {
        let orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate <= this.today && orderDate >= this.lastMonth;
      })
      this.revenueMade = monthlyOrder.length * 50;
    } else {
      let dailyOrder = this.sold.filter((order) => {
        let orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate === this.today;
      })
      this.revenueMade = dailyOrder.length * 50;
    }
  }

  countItems() {
    if (this.itemsFilter === 'yearly') {
      let yearlyOrder = this.sold.filter((order) => {
        let orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate <= this.today && orderDate >= this.lastYear;
      })
      this.serviceSold = yearlyOrder.length;
    } else if (this.itemsFilter === 'monthly') {
      let monthlyOrder = this.sold.filter((order) => {
        let orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate <= this.today && orderDate >= this.lastMonth;
      })
      this.serviceSold = monthlyOrder.length;
    } else {
      let dailyOrder = this.sold.filter((order) => {
        let orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        return orderDate === this.today;
      })
      this.serviceSold = dailyOrder.length;
    }
  }

  selectOrder(order: Order) {
    localStorage.setItem('order', JSON.stringify(order));
    this.router.navigate(['/', 'orders', order.id]).then(()=> {return;});
  }
}
